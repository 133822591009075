import { Link } from 'gatsby'
import React, { useState } from 'react'
import Logo from '../assets/Logo'
import Sign from '../assets/Sign'
import { scheduleMeetingClick } from '../utils/index'
import { alpha, colors, s } from '../style'

const IntroIndex = () => {
  const [hover, setHover] = useState(false)
  return (
    <div css={sIntro}>
      <div css={[sGlow, hover && { opacity: 1 }]} />
      <div css={sCenter}>
        {/* <Sign
          css={sSign}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        /> */}
        <Logo />
        <div css={sNav}>
          <ul>
            <li>
              <Link to="/#uslugi">Usługi</Link>
            </li>
            <li className="bull">&bull;</li>
            <li>
              <Link to="/#o-mnie">O Mnie</Link>
            </li>
            <li className="bull">&bull;</li>
            <li>
              <Link to="/portfolio/">Portfolio</Link>
            </li>
            <li className="bull">&bull;</li>
            <li>
              <Link to="/dyrektor-marketingu-na-godziny/">Dyrektor Marketingu</Link>
            </li>
            <li className="bull">&bull;</li>
            <li>
              <Link to="/tworca-marek/">Twórca marek</Link>
            </li>
            <li className="bull">&bull;</li>
            <li>
              <Link to="/#kontakt">Kontakt</Link>
            </li>
            <li className="bull">&bull;</li>
            <li>
              <a href="https://calendar.app.google/r8uYUH1wjhyAs8nh7" target="_blank" onClick={scheduleMeetingClick} >Umów spotkanie</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const sGlow = {
  pointerEvents: 'none',
  transition: 'opacity 0.2s ease-out',
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%, 0%)',
  zIndex: 0,
  width: '200vw',
  height: '100vw',
  // prettier-ignore
  background: `-webkit-radial-gradient(
      top,
      ${colors.red.concat(alpha[100])} 0%,
      ${colors.red.concat(alpha[30])} 30%,
      ${colors.red.concat(alpha[0])} 45%,
      ${colors.red.concat(alpha[0])} 60%,
      ${colors.red.concat(alpha[0])} 100%
      )`,

  opacity: 0,
  mixBlendMode: 'color',
  [s.xs]: {
    height: '200vh',
  },
}

const sIntro = {
  position: 'relative',
  top: 0,
  left: 0,
  width: '100vw',
  height: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  // padding: '4em 0',
  // prettier-ignore
  background: `-webkit-radial-gradient(
    top center, 
    circle cover, 
    ${colors.light} 0%,
    ${colors.dark} 20%,
    ${colors.darker} 40%, 
    ${colors.back} 60%
    )`,
  [s.sm_down]: {
    height: 'fit-content',
    background: `-webkit-radial-gradient(
      top center, 
      circle cover, 
      ${colors.light} 0%,
      ${colors.dark} 20%,
      ${colors.darker} 50%, 
      ${colors.back} 80%
    )`,
    // padding: '2em 0',
  },
  // [s.sm]: { minHeight: 700 },
}

const sCenter = {
  overflowX: 'hidden',
  maxWidth: '100vw',
  width: '100vw',
  height: 'fit-content',
  position: 'relative',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '4em 0',
  // [s.sm]: { minHeight: 700 },
  [s.sm_down]: {
    padding: '2em 0',
  },
}

const sSign = {
  transition: 'all 0.2s ease-out',
  mixBlendMode: 'overlay',
  width: 636,
  height: 662,
  [s.xs]: {
    maxWidth: '30%',
    height: 'auto',
  },
  [s.sm]: {
    height: 'auto',
    maxWidth: '20%',
    // maxHeight: '75vh',
  },
  [s.lg]: {
    maxWidth: '10%',
    height: 'auto',
  },
  [s.lg]: {
    maxWidth: '10%',
  },
  '&:hover': {
    transform: 'scale(1.1)',
  },
}

const sNav = {
  // maxWidth: '100vw',
  margin: '4rem 0 0',
  [s.xs]: { margin: '2rem 0 0' },
  fontFamily: 'Geometos',
  color: colors.white,
  ul: {
    display: 'flex',
    alignItems: 'center',
    apparance: 'none',
    listStyleType: 'none',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: 0,
    margin: 0,
    // [s.xs]: {
    //   flexDirection: 'column',
    // },
  },

  li: {
    opacity: 1,
    transition: 'all 0.15s ease-out',
    display: 'flex',
    apparance: 'none',
    // mixBlendMode: 'soft-light',
    // isolation: 'isolate',
    '&:hover': {
      opacity: 1,
      mixBlendMode: 'normal',
    },
  },

  '.bull': {
    color: colors.white.concat(alpha[44]),
    [s.sm_down]: { display: 'none' },
  },

  a: {
    transition: 'all 0.15s ease-out',
    display: 'flex',
    padding: '0.5rem 1.5rem',
    color: colors.white.concat(alpha[44]),
    '&:hover': {
      color: colors.white,
      transform: 'scale(1.2)',
      textShadow: '0px 0px 2rem white',
    },
    [s.md_down]: {
      padding: '0.5rem 1rem',
    },
    [s.xs]: {
      padding: '0.5rem 1rem',
    },
  },
}

export default IntroIndex
